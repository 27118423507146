import React, { useEffect, useState } from 'react'

import xIcon from '../../../../../static/svg/close.svg'

import { cart, IProduct } from '../../../../@types'
import { getCurrentCart } from '../../../../functions/getCurrentCart'
import {
  CartQuantitySelect,
  CartRemove,
  Container,
  ControlPanel,
  Description,
  ImageContainer,
  Info,
  ItemImage,
  Price,
} from './styles'

interface IProps {
  product: IProduct
  setCurrentCart: React.Dispatch<React.SetStateAction<cart | null>>
}

export function CartItem({ product, setCurrentCart }: IProps) {
  const {
    photo,
    quantity: initialQuantity,
    selectedVariant,
    photoTitle,
  } = product

  const [quantity, setQuantity] = useState<number>(initialQuantity)

  useEffect(() => {
    setCurrentCart(getCurrentCart())
  }, [quantity])

  function handleQuantityChange(newQuantity: number) {
    setQuantity(newQuantity)
  }

  return (
    <Container>
      <ImageContainer>
        <ItemImage
          alt={product.photoTitle}
          fluidAsset={photo.asset.fluidSmall}
        />
        <Info>
          <Description>
            <h2>{photoTitle}</h2>
            <p>{selectedVariant.variantTitle}</p>
          </Description>
          <Price>
            <span>$</span>
            {selectedVariant.price}
          </Price>
        </Info>
      </ImageContainer>

      <ControlPanel>
        <CartQuantitySelect
          product={product}
          onQuantityChange={handleQuantityChange}
          currentQuantity={quantity}
        />
        <CartRemove product={product} setCurrentCart={setCurrentCart}>
          Remove
          <img src={xIcon} alt='X icon' />
        </CartRemove>
      </ControlPanel>
    </Container>
  )
}
