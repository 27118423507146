import styled from 'styled-components'

import { rhythm } from '../../../../utils/typography'
import { Image } from '../../../common/Image'
import { QuantitySelect } from '../../../common/QuantitySelect'
import { RemoveFromCartButton } from '../../RemoveFromCartButton'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: fit-content;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  max-width: 80vw;
  width: 100%;
  justify-self: center;
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`

export const ItemImage = styled(Image)`
  height: auto;
  width: 100%;
  margin: 0 auto;
  transition: all 1s;
  border-radius: 10px 10px 0 0;
`

export const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: var(--white);
  background: #00000066;
  width: 100%;
  height: 100%;
  padding: ${rhythm(1)};
  transition: opacity 0.7s;
  border-radius: 10px 10px 0 0;
  display: flex;

  :hover {
    opacity: 0;
  }
`

export const Description = styled.div`
  h2 {
    margin-bottom: 0;
    line-height: ${rhythm(1)};
    padding-bottom: ${rhythm(1 / 2)};
  }

  p {
    color: #eee;
    font-size: 1.1rem;
  }
`

export const Price = styled.span`
  font-size: 2.3rem;
  line-height: 1.8rem;
  margin-left: auto;
  align-self: flex-end;
  display: flex;

  span {
    font-size: 1.3rem;
    line-height: 1.3rem;
    align-self: flex-start;
  }
`

export const ControlPanel = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: ${rhythm(1 / 2)};
`

export const CartQuantitySelect = styled(QuantitySelect)`
  margin: 0;
  justify-self: flex-start;
`

export const CartRemove = styled(RemoveFromCartButton)`
  color: var(--black);
  text-decoration: underline var(--primary);
  display: inline-flex;
  align-items: center;
  align-self: center;
  justify-self: flex-start;
  justify-content: center;
  width: fit-content;
  height: fit-content;

  img {
    height: 1.5rem;
    margin-bottom: 0;
    margin-left: 0.1rem;
  }
`
