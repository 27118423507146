import React from 'react'
import { cart, IProduct } from '../../@types'
import { getCurrentCart } from '../../functions/getCurrentCart'
import { updateCart } from '../../functions/updateCart'
import { IconButton } from '../../globalStyles'
import { CartUpdates } from '../../vars'

interface IProps {
  product: IProduct
  setCurrentCart: React.Dispatch<React.SetStateAction<cart | null>>
  className?: string
  children?: React.ReactChild[] | React.ReactChild
}

export function RemoveFromCartButton({
  product,
  setCurrentCart,
  className,
  children,
}: IProps) {
  function handleClick(): void {
    updateCart(product, CartUpdates.REMOVE)

    setCurrentCart(getCurrentCart())
  }

  return (
    <IconButton
      onClick={handleClick}
      value='Remove from cart'
      title='Remove from cart'
      className={className}
    >
      {children ? children : null}
    </IconButton>
  )
}
