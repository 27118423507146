import React, { ReactElement } from 'react'

import { cart, IProduct } from '../../../@types'
import { CartItem } from './CartItem/index'
import { Container } from './styles'

interface IProps {
  currentCart: cart
  setCurrentCart: React.Dispatch<React.SetStateAction<cart | null>>
}

export function Cart({ currentCart, setCurrentCart }: IProps) {
  const cartItems: ReactElement[] | null = currentCart.map(
    (product: IProduct, ind: number) => {
      const key = `${product.desc}${ind}`
      return (
        <CartItem key={key} product={product} setCurrentCart={setCurrentCart} />
      )
    }
  )

  return <Container>{cartItems}</Container>
}
