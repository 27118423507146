import styled from 'styled-components'
import { rhythm } from '../../../utils/typography'

export const Container = styled.section`
  --item-min-max: minmax(200px, 400px);

  display: grid;
  grid-template-columns: repeat(auto-fit, var(--item-min-max));
  gap: ${rhythm(1)};
  justify-content: center;
  margin-bottom: ${rhythm(1)};
`
