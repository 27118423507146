import styled from 'styled-components'

import { rhythm } from '../../utils/typography'
import { mediaQueries } from '../../vars'
import { ButtonLink, cardEnterAnim } from '../../globalStyles'

export const Container = styled.div`
  background: #ffffffdd;
  max-width: 1300px;
  border-radius: 10px;
  /* Animation fill mode backwards is important because if transform: scale is on this element its children cannot be fixed */
  animation: ${cardEnterAnim} 0.6s 0.2s backwards;
  display: flex;
  flex-direction: column;
  margin: ${rhythm(1)} auto 0;
  position: relative;

  @media ${mediaQueries.general.desktop}, ${mediaQueries.portrait.tablet} {
    padding: ${rhythm(2)};
    padding-top: 0;
  }

  @media (min-width: 1500px) and (orientation: landscape) {
    padding: ${rhythm(2)};
    box-shadow: var(--box-shadow);
  }
`

export const CartContainer = styled.div`
  width: 100%;
  display: grid;
  grid-column-gap: 0;
  column-gap: 0;
  position: relative;
  padding: ${rhythm(1 / 2)};
  margin-top: ${rhythm(1)};

  @media ${mediaQueries.portrait.tablet}, (orientation: landscape) {
    grid-template-columns: 3fr 2fr;
  }
`

export const Header = styled.header`
  width: 100%;
  margin: ${rhythm(1)} auto;
  border-bottom: 3px solid #00000011;

  h1 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  h1 img {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
`

export const Sidebar = styled.div`
  height: fit-content;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: ${rhythm(1)};
  margin: ${rhythm(1 / 2)};
  background: #ffffff;
  box-shadow: var(--box-shadow-light);
  border-radius: 5px;
  padding: ${rhythm(1)};
  z-index: 1;

  @media ${mediaQueries.general.desktop} {
    top: calc(var(--nav-height) + ${rhythm(1)});
  }

  @media ${mediaQueries.portrait.handheld} {
    position: sticky;
    width: 60%;
    bottom: ${rhythm(1 / 2)};
    left: 0;
    top: initial;
    margin: 0;
    box-shadow: var(--box-shadow);
    background: #ffffffee;
  }
`

export const PriceLabel = styled.label`
  font-size: 0.9rem;
  color: #333;
  display: block;
`

export const PriceSpan = styled.span`
  font-weight: bold;
`

export const Link = styled(ButtonLink)`
  color: var(--black);
  box-shadow: var(--box-shadow-light);
  background: transparent;
  margin-top: ${rhythm(1 / 2)};
  font-weight: bold;
  width: 100%;
  padding: ${rhythm(1 / 4)};
  border-radius: 5px;
  border: 1px solid var(--primary);
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;

  img {
    height: 1.3rem;
    margin-left: 0.2rem;
  }
`

export const NoItems = styled.section`
  animation: ${cardEnterAnim} 0.6s 0.2s backwards;
  position: relative;
  background: #ffffffdd;
  padding: ${rhythm(2)};
  border-radius: 10px;
  width: fit-content;
  margin: ${rhythm(1)} auto;
  box-shadow: var(--box-shadow-very-light);

  h1 {
    font-size: 2rem;
    margin-bottom: ${rhythm(2)};

    @media ${mediaQueries.general.handheld} {
      font-size: 1.3rem;
      line-height: 1.9rem;
      margin-bottom: ${rhythm(1)};
    }
  }

  @media ${mediaQueries.general.handheld} {
    box-shadow: unset;
    padding: ${rhythm(1)};
  }
`
