import React, { useState } from 'react'

import cartIcon from '../../../static/svg/cart.svg'
import imageIcon from '../../../static/svg/image.svg'
import arrowIcon from '../../../static/svg/play.svg'

import { cart } from '../../@types'
import { getCurrentCart } from '../../functions/getCurrentCart'
import { PageTitles, PageURLs } from '../../vars'
import { Layout } from '../common/Layout'
import { Cart } from './Cart'
import {
  CartContainer,
  Container,
  Header,
  Link,
  NoItems,
  PriceLabel,
  PriceSpan,
  Sidebar,
} from './styles'

export function CartPage() {
  const [currentCart, setCurrentCart] = useState<cart | null>(getCurrentCart())

  if (!currentCart) {
    return (
      <Layout darkBg={false} currentPageTitle={PageTitles.Cart}>
        <NoItems>
          <h1>There are no items in your cart</h1>
          <Link to={PageURLs.Photos}>
            Find photos <img src={imageIcon} alt='Image icon' />
          </Link>
        </NoItems>
      </Layout>
    )
  }

  const subTotal = currentCart.reduce(
    (acc, item) => acc + item.quantity * item.selectedVariant.price,
    0
  )

  const itemsLength = currentCart.reduce((acc, item) => acc + item.quantity, 0)

  return (
    <Layout darkBg={false} currentPageTitle={PageTitles.Cart}>
      <Container>
        <Header>
          <h1>
            Your cart <img src={cartIcon} alt='Cart Icon' />
          </h1>
        </Header>
        <CartContainer>
          <Cart currentCart={currentCart} setCurrentCart={setCurrentCart} />

          <Sidebar>
            <PriceLabel htmlFor='subtotal-span'>
              Subtotal ({itemsLength} item{itemsLength === 1 ? '' : 's'}):
            </PriceLabel>
            <PriceSpan id='subtotal-span'>${subTotal}</PriceSpan>

            <Link to={PageURLs.Checkout} state={{ currentCart }}>
              Checkout
              <img src={arrowIcon} alt='Arrow circle icon' />
            </Link>
          </Sidebar>
        </CartContainer>
      </Container>
    </Layout>
  )
}
